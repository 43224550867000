import React from 'react';
import { motion, useInView } from 'framer-motion';

const AnimatedWrapper = ({ children }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref,);

  const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.4 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedWrapper;
