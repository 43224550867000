import React from 'react'
import ExperienceBox from './ExperienceBox'

const Experience = () => {
  return (
    <div className='mt-4 py-8 bg-black p-4 flex flex-col text-white'>
      <div className='text-sm text-center'> My <b>Experience</b></div>
      <div className='flex flex-wrap gap-2 mt-4'>
               <ExperienceBox/> 
      </div>
    </div>
  )
}

export default Experience