import React from 'react'

const ExperienceBox = () => {
  return (
    <div className='hover-effect md:max-w-screen-lg m-auto flex flex-col gap-8 border border-zinc-500 p-4 rounded-md'>
        <div className='flex gap-4 flex-col md:flex-row md:justify-between'>
            <h5>Software Engineer - India Based MNC</h5>
            <h6>Oct 2020 - Present</h6>
        </div>
        <p className='text-p2 md:text-p2'>
        Results-driven and highly skilled software engineer with over 3.5+ years of experience in designing and
        implementing cutting-edge web development, data intensive applications, ETL solutions.
        </p>
    </div>
  )
}

export default ExperienceBox