import React from 'react'
import ExperienceBox from './ExperienceBox'
import ProjectBox from './ProjectBox';
import Chart from '../resources/image.png';
import Travel from '../resources/travel.png';
import OnlineGaming from '../resources/color1.png';

const Projects = () => {
  return (
    <div id="projects" className='mt-4 py-8 bg-black p-4 flex flex-col text-white'>
      <div className='text-sm text-center'> My <b>Projects</b></div>
      <div className='flex flex-wrap gap-4 mt-4'>
        
        
        <ProjectBox flexReverse={false} img={OnlineGaming} title={'Online Gaming Platform'} desc={'Online Gaming Platform built using Express.js, Socket.io, MySql'} />
        <ProjectBox flexReverse={true} img={Travel} title={'Tours & Travel Website'} desc={'MERN Stack Development'} />
        <ProjectBox flexReverse={false} img={Chart} title={'Live Chart Dashboard'} desc={'React Dashboard With Charts'} />
      </div>
      <div className='text-right'>and many more...</div>
    </div>
  )
}

export default Projects