import React from 'react';

const ProjectBox = ({img, title, desc,flexReverse}) => {
    return (
        <div className={`hover-effect md:w-full ${flexReverse == true ? 'md:flex-row-reverse': null} m-auto flex md:flex-row flex-col gap-8  p-4 rounded-md`}>
            <div className='md:w-1/2'>
                <img src={img} width={'100%'} height={'auto'} style={{objectFit:"contain"}} className='h-[45vh]' />
            </div>
            <div className='flex flex-col gap-8'>
                <h4 className='font-bold'>{title}</h4>
                <div>{desc}</div>
            </div>
        </div>
    )
}

export default ProjectBox