import React, { useEffect, useRef } from 'react'
import AboutImg from '../resources/about.svg';
import { motion, useAnimation, useInView } from 'framer-motion';


const About = () => {

    const ref = useRef(null);
    const isInView = useInView(ref, {once:true});

    const mainControl = useAnimation();

    useEffect(()=>{
        if(isInView){
            mainControl.start("visible");
        }
    },[isInView])

    return (
        <div id="about" ref={ref} className='p-4 m-auto gap-4 flex flex-col justify-center items-center'>
            <div className='text-sm text-center'>About <b>Me</b></div>
            <div className='md:w-full md:flex md:justify-center md:items-center'>


                <motion.div
                    variants={{
                        hidden: { y: -75 },
                        visible: { y: 0 },
                        rotate:{rotate:-2}
                    }}
                    initial="hidden"
                    animate={mainControl}
                    whileHover="rotate"
                    // transition={{ duration: 0.5 }}
                    className='md:w-1/2'>
                    <img src={AboutImg} className='h-[55vh]' />
                </motion.div>
                <div className='md:w-1/2 flex flex-col items-start gap-2 tracking-wider'>
                    <p className='text-p2'>Hi there!
                        I am Utsav Patel with over 4+ years of experience with Expertise in Full Stack Web Development and Data Engineering.
                    </p>
                    <p className='text-p2'>I am a skilled and passionate React JS and MERN Stack Developer with a deep appreciation for creating dynamic and responsive web applications. With a robust foundation in web development, I specialize in leveraging the power of the MERN (MongoDB, Express, React, Node.js) stack to craft innovative, user-centric, and high-performance digital experiences.
                    </p><p className='text-p2'>
                        Also Well-versed with creation of ETL pipelines and big data technologies like Hadoop, Apache Spark, Sqoop, Yarn. Proficient in python and unix scripting for data analysis scripting.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About