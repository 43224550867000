import React from 'react';
import HeroImg from '../resources/column.svg';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import Reveal from './reusable/Reveal';
import { motion } from 'framer-motion'

const HeroSection = () => {
    return (
        <div className='p-4 m-auto gap-4 flex flex-col md:flex-row justify-center md:items-center'>
            <motion.div
            
            variants={
                {
                    hidden:{rotate:0},
                    visible:{rotate:-2}
                }
            }
            initial="hidden"
            animate="visible"
            whileHover="hidden"
            className='md:w-1/2'>
                <img src={HeroImg} className='h-[55vh]' />
            </motion.div>
            <div className='md:w-1/2 flex flex-col items-start gap-2 tracking-wider'>
                
                    <div className='text-sm md:text-base'>
                        Hello I'm <b>Utsav Patel</b>
                    </div>
                <Reveal>
                <div className='text-sm md:text-base'>
                    <b>Full Stack </b>Developer
                </div>
                </Reveal>
                <div className='text-sm md:text-base'>
                    Based In <span >India</span>
                </div>
                <div>
                    <p className='text-p2'>
                    with over 4+ years of experience with Expertise in Full Stack Web Development and Data Engineering.
                       </p>
                </div>
                <div className='flex gap-4 mt-4'>
                    <button className='bg-black p-4 text-white rounded-md transition-transform transform hover:-translate-y-1'><FacebookRoundedIcon color='#000' /></button>
                    <button className='p-4 rounded-md border-2 border-black transition-transform transform hover:-translate-y-1'><RedditIcon /></button>
                    <button className='p-4 rounded-md border-2 border-black transition-transform transform hover:-translate-y-1'><TwitterIcon /></button>
                    <button className='p-4 rounded-md border-2 border-black transition-transform transform hover:-translate-y-1'><GitHubIcon /></button>
                </div>
            </div>
        </div>
    )
}

export default HeroSection