import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Skills from './components/Skills';
import Experience from './components/Experience';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import AnimatedWrapper from './components/reusable/AnimatedWrapper';

function App() {
  return (
    <div className='font-sora'>
      <Header/>
      <div className='container m-auto'>
      <HeroSection/>
      <AnimatedWrapper><Skills/></AnimatedWrapper>
      <AnimatedWrapper><Experience/></AnimatedWrapper>
      <AnimatedWrapper><About/></AnimatedWrapper>
      <AnimatedWrapper><Projects/></AnimatedWrapper>
      <Contact/>
      </div>
    </div>
  );
}

export default App;
