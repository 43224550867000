import React, { useState } from 'react'
import Logo from '../resources/Group.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {

    const [isMenu, setIsMenu] = useState(false);

    const handleClick = () => {
        setIsMenu((prev) => !prev);
    }

    return (
        <>
            <div className='p-4 sticky top-0 bg-black text-white z-50'>
                <div className='flex items-center justify-between'>

                    <div className='flex gap-4 ml-4 md:ml-8 items-center text-white'>
                        {/* <img src={Logo} width={23} height={34} color='white'/> */}
                        <h4 className='font-bold text-xs'>Utsav Patel</h4>
                    </div>
                    <div className='md:hidden'>
                        <button onClick={() => { handleClick() }}>
                            <MenuIcon />
                        </button>
                    </div>
                    <ul className='hidden md:flex md:gap-8'>
                            <li><a href="#about">About Me</a></li>
                            <li><a href="#skills">Skills</a></li>
                            <li><a href="#projects">Projects</a></li>
                            <li><a href='#contact'>Contact Me</a></li>
                    </ul>
                </div>
            </div>
            {<div className={`fixed top-0 right-0 z-50 h-[100vh] w-[65vw] bg-black text-white transform transition-transform duration-500 ${isMenu ? 'translate-x-0 animate-slideIn' : 'translate-x-full animate-slideOut'}`}>
                <div className='p-4'>
                    <div className='flex items-center justify-between'>

                        <div className='flex gap-4 items-center'>
                            {/* <img src={Logo} width={23} height={34} /> */}
                            {/* <h4 className='font-bold'>Utsav Patel</h4> */}
                        </div>
                        <button onClick={() => { handleClick() }}>
                            <CloseIcon color='#000' />
                        </button>
                    </div>
                    <div>
                        <ul className='flex flex-col gap-8 py-4'>
                            <li>About Me</li>
                            <li>Skills</li>
                            <li>Projects</li>
                            <li>Contact Me</li>
                        </ul>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Header