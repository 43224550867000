import React from 'react';
import IconExpress from '../resources/icon-express.svg';

const SkillsBox = ({imgSrc, title}) => {
  return (
    <div className="hover-effect w-[calc(50%-0.5rem)] md:w-[calc(20%-0.5rem)]  rounded-md border-2 border-black flex flex-col items-center justify-center h-48">
        <img src={imgSrc}/>
        <h5>{title}</h5>
    </div>
  )
}

export default SkillsBox