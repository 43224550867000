import React from 'react'
import SkillsBox from './SkillsBox';
import ExpressJsIcon from '../resources/icon-express.svg';
import JavascriptIcon from '../resources/icon-javscript.svg';
import GitIcon from '../resources/icon-git.svg';
import NestIcon from '../resources/icon-nest.svg';
import NextJSIcon from '../resources/icon-nextjs.svg';
import NodeJSIcon from '../resources/icon-nodejs.svg';
import ReactJSIcon from '../resources/icon-react.svg';
import SocketIcon from '../resources/icon-socket.svg';
import TailwindIcon from '../resources/icon-tailwindcss.svg';
import TypeScriptIcon from '../resources/icon-typescript.svg';

const Skills = () => {

    
    return (
        <div id="skills" className='mt-4 p-4 flex flex-col'>
            <div className='text-sm text-center'> My <b>Skills</b></div>
            <div className='flex flex-wrap gap-2 mt-4'>
                <SkillsBox imgSrc={NodeJSIcon} title={'Node JS'} />
                <SkillsBox imgSrc={ExpressJsIcon} title={'Express Js'} />
                <SkillsBox imgSrc={JavascriptIcon} title={'JavaScript'} />
                <SkillsBox imgSrc={TypeScriptIcon} title={'TypeScript'} />
                <SkillsBox imgSrc={ReactJSIcon} title={'React.JS'} />
                <SkillsBox imgSrc={NextJSIcon} title={'Next.JS'} />
                <SkillsBox imgSrc={NestIcon} title={'Nest JS'} />
                <SkillsBox imgSrc={TailwindIcon} title={'Tailwind CSS'} />
                <SkillsBox imgSrc={SocketIcon} title={'Socket.io'} />
                <SkillsBox imgSrc={GitIcon} title={'Git'} />
                
            </div>
        </div>
    )
}

export default Skills