import { motion, useAnimation, useInView } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

const Reveal = ({children}) => {


    const ref = useRef(null);

    const isInView = useInView(ref);
    

    const mainControl = useAnimation();
    const slideAnimation = useAnimation();

    useEffect(()=>{
        if(isInView==true)
        mainControl.start("visible");
        slideAnimation.start("visible");
    },[isInView])


  return (
    <div ref={ref} className='relative '>
        <motion.div
        variants={{
            hidden:{opacity:0, y:10},
            visible:{opacity:1, y:0},
        }}
        initial="hidden"
        animate={mainControl}
        transition={{duration:0.5, delay:0.1}}
        >
        {children}
        </motion.div>

        {/* sideAnimation */}

        <motion.div
        style={{
            position:'absolute',
            background:"black",
            top:0,
            left:0,
            bottom:0,
            right:0
        }}
        variants={{
            hidden:{left:"0"},
            visible:{left:"100%"}
        }}
        initial="hidden"
        animate={slideAnimation}
        transition={{duration:0.5, delay:0.2}}
        />
    </div>
  )
}

export default Reveal