import React from 'react'

const Contact = () => {
  return (
    <div id="contact" className='p-4 m-auto gap-4 flex flex-col md:flex-row justify-center items-center mt-10'>
        <div className='md:w-1/2 w-full'>
        <div className='w-full flex flex-col gap-4'>

            <input type='text' className='w-full px-8 py-4 border-2 border-black rounded-md' placeholder='Name:'/>
            <input type='text' className='w-full  px-8 py-4 border-2 border-black rounded-md' placeholder='Contact No:'/>
            <input type='text' className='w-full px-8 py-4 border-2 border-black rounded-md' placeholder='Contact Mail:'/>
            <textarea type='text' className=' w-full px-8 py-4 border-2 border-black rounded-md' placeholder='Purpose for contact:'/>

            <button className='bg-black p-4 text-white rounded-md transition-transform transform hover:-translate-y-1'>Get in touch</button>
        </div>
        </div>
        <div className='md:w-1/2 w-full'>
            <div className="flex flex-col gap-4 justify-start md:p-16 p-4">
                  <h3 className='text-sm font-bold'>
                    Let's talk for
                  </h3>
                  <h3 className='text-sm font-bold'>Something Special!</h3>
                  <p className='text-p2'>I seek to push the limits of creativity to create high-engaging, user-friendly, and memorable interactive experiences.</p>
                  <div className='text-sm'><b>utsav2727@gmail.com</b></div>
                  <div className='text-sm'>+91 8758830465</div>
            </div>
        </div>
    </div>
  )
}

export default Contact